import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    comment: {
        content: '',
        createdAt: Date.now(),
        userId: '',
        postId: ''
    },
    comments: []
}

// Store in post
export const postComment = createAsyncThunk("/comments/post", async (data) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${data.token}`
        } 
    });
    const result = await axiosInstance.post('/comments/post',data);
    if (result.data.error === "") {
        return true;
    } else {
        return false;
    }
})
export const getPostComments = createAsyncThunk("/comments/getbypost", async (data) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000
    });
    const comments = await axiosInstance.get(`/comments/getpostcomments/${data.postId}`);
    return comments.data;
})
export const getUserComments = createAsyncThunk("/comments/getbyuser", async (data) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${data.token}`
        } 
    });
    const comments = await axiosInstance.get(`/comments/getusercomments`);
    return comments.data;
})
const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(getPostComments.pending, (state,action) => {
            state.comments = [];
        })
        builder.addCase(getPostComments.fulfilled, (state,action) => {
            state.comments = action.payload;
        })
        builder.addCase(getUserComments.pending, (state,action) => {
            state.comments = [];
        })
        builder.addCase(getUserComments.fulfilled, (state,action) => {
            state.comments = action.payload;
        })
    }
})
export default commentSlice.reducer;