import React from "react";
import RegisterForm from "./forms/RegisterForm";
import Logo from "../shared/Logo";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useState } from "react";
import { tryRegister } from "../app/features/userSlice";
import { useSelector } from "react-redux";
import {Navigate} from 'react-router-dom'
const Register = () => {
    const isLoggedIn = useSelector((state) => state.user.loggedIn);
    const dispatch = useDispatch();
    const [isRegistering, changeRegister] = useState(false);
    const [navAway, shouldNav]  = useState(false);
    const handleResp = (resp) => {
      if (resp.loggedIn) {
         shouldNav(true);
         changeRegister(false);
      } else {
         alert("Unable to login")
      }
    }
    const submitForm = (data) => {
      changeRegister(true);
      dispatch(tryRegister(data)).then((resp) => {
         handleResp(resp.payload)
      }); 
    }
    return (
        <>
            {isLoggedIn && (<Navigate to = "/" />)}
            {navAway && (<Navigate to = "/" />)}
            {!isRegistering && (
                <>
                    <div style={{ paddingTop: "5%", marginBottom: "5%" }}>
                        <Logo />
                    </div>
                    <RegisterForm onSubmit={submitForm}/>
                </>
            )}
            {isRegistering && (
                <>
                    <div className="text-center">
                        <div
                            className="spinner-border text-warning"
                            role="status"
                            style={{
                                paddingTop: "30%",
                                width: "30%",
                            }}
                        ></div>
                    </div>
                </>
            )}
        </>
    );
};
export default Register;
