import React from "react";
import { useState, useEffect } from "react";
import {Navigate} from 'react-router-dom'
import { logOut } from '../app/features/userSlice';
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { Spinner } from "./components/Spinner";
const Logout = () => {
    const [redirect, changeRedirect] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logOut());
        setTimeout(() => changeRedirect(true),500);
    // eslint-disable-next-line
    },[])
    return (
        <>
        {redirect && <Navigate to = "/"/>}
        <Spinner />
        </>
    );
};
export default Logout;
