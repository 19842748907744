import React, { useEffect, useState } from "react";

import { validToken } from "../verification/verifyUser";
import { Navigate } from 'react-router-dom';

import { useSelector } from "react-redux";
import { getPosts } from "../app/features/contentSlice";
import { useDispatch } from "react-redux/es/exports";
import PostCard from "./components/PostCard";
import { Spinner } from "./components/Spinner";
const Home = () => {
    // Consult store
    const token = useSelector(state => state.user.token);
    const logState = useSelector(state => state.user.loggedIn);
    
    // Set initial state
    const [loadingData, changeLoad] = useState(true);
    const [posts,changePosts] = useState({});
    const [show404,change404] = useState(false);
    const [logout,shouldLogout] = useState(false);
    // define variables
    const dispatch = useDispatch();
    // Only runs on initial run
    useEffect(() => {
        if (validToken(token,logState)) {
            shouldLogout(true);     
        } else {
            dispatch(getPosts()).then((res) => {
                changePosts(res.payload);
                changeLoad(false);
            }).catch(error => {
                change404(true);
            })
    }
    // eslint-disable-next-line
    }, []);
    return (
        <>
            {logout && <Navigate to = "/logout" />}
            {show404 && (<Navigate to = "/page404" />)}
            {loadingData && (<Spinner />)}
            {!loadingData && <h1 style = {{marginBottom: "3%"}}>Blog Posts</h1>}
            {!loadingData &&
                Object.keys(posts).map((key) => {
                    if (key === "id") {
                        return null;
                    } else {
                        return (
                                <div className="row" key = {key} style = {{marginBottom: "2%", marginTop: "2%", display: "flex", justifyContent: "center"}}>
                                    <PostCard
                                        to = {`/posts/${key}`}
                                        title={posts[key].title}
                                        subtitle={posts[key].subtitle}
                                        text={posts[key].content}
                                        key = {key}
                                        id = {key}
                                    />
                                </div>
                        );
                    }
                })
                }
        </>
    );
};

export default Home;
