import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import axios from "axios";
const initialState = {
    loggedIn: false,
    token: '',
    error: '',
    email: '',
    username: '',
    id: ''
}
export const tryLogin = createAsyncThunk('user/tryLogin', async (data) => {
    const axiosInstance = axios.create({
        baseURL : '/api/v1',
        timeout: 5000 // everything longer than 5 secs is considered a failed request
    });
    try {
        const resp = await axiosInstance.post('/users/login', data);
        const ret = resp.data;
        if (ret.err) {
            return {
                loggedIn: false,
                token: '',
                error: ret.err,
                email: '',
                username: '',
                id: ''
            }
        } else {
            return {
                loggedIn: true,
                token: ret.tok,
                error: '',
                email: ret.email,
                username: ret.username,
                id: ret.id
            }
        }
    } catch (err) {
        return {
            loggedIn: false,
            token: '',
            error: err,
            email: '',
            username: '',
            id: ''
        }
    }
});
export const tryRegister = createAsyncThunk('user/tryRegister', async (data) => {
    const axiosInstance = axios.create({
        baseURL : '/api/v1',
        timeout: 5000 // everything longer than 5 secs is considered a failed request
    });
    try {
        const resp = await axiosInstance.post('/users/register', data);
        const ret = resp.data;
        if (ret.err) {
            return {
                loggedIn: false,
                token: '',
                error: ret.err,
                email: '',
                username: '',
                id: ''
            }
        } else {
            return {
                loggedIn: true,
                token: ret.tok,
                error: '',
                email: ret.email,
                username: ret.username,
                id: ret.id
            }
        }
    } catch (err) {
        return {
            loggedIn: false,
            token: '',
            error: err,
            email: '',
            username: '',
            id: ''
        }
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logOut: (state) => {
            state.loggedIn = false;
            state.error = '';
            state.token = '';
            state.email = '';
            state.username = '';
            state.id = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(tryLogin.pending, state => {
            state.loggedIn = false;
            state.error = '';
            state.token = '';
            state.email = '';
            state.username = '';
            state.id = '';
        })
        builder.addCase(tryLogin.fulfilled, (state,action) => {
            state.loggedIn = action.payload.loggedIn;
            state.error = action.payload.error;
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.username = action.payload.username;
            state.id = action.payload.id;
        })
        builder.addCase(tryRegister.pending, state => {
            state.loggedIn = false;
            state.error = '';
            state.token = '';
            state.email = '';
            state.username = '';
            state.id = '';
        })
        builder.addCase(tryRegister.fulfilled, (state,action) => {
            state.loggedIn = action.payload.loggedIn;
            state.error = action.payload.error;
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.username = action.payload.username;
            state.id = action.payload.id;
        })
        builder.addCase(PURGE, (state) => {
            state.loggedIn = false;
            state.error = '';
            state.token = '';
            state.email = '';
            state.username = '';
            state.id = '';
        })
    }
})

export default userSlice.reducer;
export const { logOut } = userSlice.actions;