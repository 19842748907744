import React from "react";
import Logo from "../shared/Logo";
import LoginForm from "./forms/LoginForm";
import { tryLogin } from "../app/features/userSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
const Login = () => {
    // eslint-disable-next-line
    const isLoggedIn = useSelector((state) => state.user.loggedIn);
    const handleResponse = (resp) => {
        const { error } = resp;
        if (error) {
            dispLoginError(error);
            changeNav(false);
            changeLoggingIn(false);
        } else {
            changeNav(true);
        }
    };
    const dispatch = useDispatch();
    const [loginError, dispLoginError] = useState(false);
    const [shouldNav, changeNav] = useState(false);
    const [loggingIn, changeLoggingIn] = useState(false);
    const submitForm = (data) => {
        changeLoggingIn(true);
        dispatch(tryLogin(data)).then((resp) => handleResponse(resp.payload));
    };
    return (
        <>
            {isLoggedIn && (<Navigate to = "/" />)}
            {shouldNav && <Navigate to="/" />}
            {loggingIn && (
                <div className="text-center">
                    <div
                        className="spinner-border text-warning"
                        role="status"
                        style={{
                            paddingTop: "30%",
                            width: "30%",
                        }}
                    ></div>
                </div>
            )}
            {!loggingIn && (
                <>
                    <div style={{ paddingTop: "5%" }}>
                        <div style = {{marginBottom: "5%"}}>
                        <Logo />
                        </div>
                        <p style={{ minHeight: "5%" }}></p>
                        <LoginForm onSubmit={submitForm} loginError = {loginError.length > 1 ? true : false} errorMessage = {loginError.length > 1 ? loginError : null} />
                    </div>
                </>
            )}
        </>
    );
};
export default Login;
