import React from "react";

export const Spinner = () => {
    return (
        <div className="text-center">
            <div
                className="spinner-border text-warning"
                role="status"
                style={{
                    paddingTop: "30%",
                    width: "30%",
                }}
            ></div>
        </div>
    );
};
