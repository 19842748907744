import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormWarning } from "./forms/FormWarning";
import { createPost } from "../app/features/postSlice";
// Need to make sure that only asmin user can create this page
// Need to make sure that only adminuser can create content
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/exports";
import { Navigate } from "react-router-dom";
import { cleanPost } from "../app/features/postSlice";
const PostCreate = () => {
    // eslint-disable-next-line
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const [preview, changePreview] = useState(false);
    const [subtitle, changeSub] = useState(false);
    const [isLoading, changeLoad] = useState(false);
    // eslint-disable-next-line
    const [shouldNav, changeNav] = useState(false);
    const handleSub = () => {
        changeSub(!subtitle);
    };
    const [dispError,changeDispError] = useState(false);
    const token = useSelector(state => state.user.token);
    const email = useSelector(state => state.user.email);
    const handlePreview = () => {
        changePreview(!preview);
    };
    const onSubmit = (data) => {
        changeDispError(false);
        changeLoad(true);
        var dataSend = data;
        dataSend.subtitle = subtitle ? dataSend.subtitle : "";
        dataSend = {
            ...dataSend,
            token: token
        }
        dispatch(createPost(dataSend)).then((resp) => {
            var data = resp.payload;
            data.comments = [];
            const stringKey = data.id;
            var dataToSend = {
                refBy: stringKey
            }
            dataToSend[data.id] = {
                title: data.title,
                subtitle: data.subtitle,
                content: data.content,
                comments: data.comments
            };
            if (resp.payload.id.length > 0) {
                dispatch(cleanPost());
                changeLoad(false);
                changeNav(true);
            } else {
                changeLoad(false);
                changeDispError(true);
            }
        });
    };
    useEffect(() => {
        if (email !== "fmb201704@gmail.com") {
            changeNav(true);
        }
    },[email])
    return (
        <>
            {" "}
            {isLoading && (
                <div className="text-center">
                    <div
                        className="spinner-border text-warning"
                        role="status"
                        style={{
                            paddingTop: "30%",
                            width: "30%",
                        }}
                    ></div>
                </div>
            )}

            {shouldNav && <Navigate to="/" />}
            
            {!isLoading && preview && (
                <>
                    <h1>This is the preview</h1>
                    <div
                        style={{
                            marginTop: "3%",
                            paddingLeft: "2%",
                            paddingRight: "0%",
                            marginBottom: "3%",
                        }}
                        className="container"
                    >
                        <div className="row">
                            <div className="col" style={{ paddingLeft: "0%" }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={handlePreview}
                                >
                                    Hide Preview
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!isLoading && !preview && (
                <>
                    <h1 style={{ fontSize: "4vh" }}>Submit Post</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ marginTop: "5%" }}>
                            <div className="input-group mb-3">
                                <span
                                    className="input-group-text"
                                    id="inputGroup-sizing-default"
                                >
                                    Title
                                </span>
                                <input
                                    {...register("title", { required: true })}
                                    name="title"
                                    type="text"
                                    className="form-control"
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-sm"
                                />
                            </div>
                            {errors.title?.type === "required" && (
                                <FormWarning message="Please enter a title." />
                            )}
                        </div>
                        {!subtitle && (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={handleSub}
                            >
                                Add Subtitle
                            </button>
                        )}
                        {subtitle && (
                            <>
                                <div style={{ marginTop: "3%" }}>
                                    <div className="input-group mb-3">
                                        <span
                                            className="input-group-text"
                                            id="inputGroup-sizing-default"
                                        >
                                            Subtitle
                                        </span>
                                        <input
                                            {...register("subtitle")}
                                            name="subtitle"
                                            type="text"
                                            className="form-control"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={handleSub}
                                >
                                    Remove Subtitle
                                </button>
                            </>
                        )}
                        <div
                            className="form-floating"
                            style={{ marginTop: "5%" }}
                        >
                            <textarea
                                {...register("content", { required: true })}
                                name="content"
                                className="form-control"
                                placeholder="Enter the body content here."
                                id="floatingTextarea2"
                                style={{ height: "700px" }}
                            ></textarea>
                        </div>
                        {errors.content?.type === "required" && (
                            <FormWarning message="Please enter your blog post." />
                        )}
                        <div
                            style={{
                                marginTop: "3%",
                                paddingLeft: "2%",
                                paddingRight: "0%",
                                marginBottom: "3%",
                            }}
                            className="container"
                        >
                            <div className="row">
                                <div
                                    className="col"
                                    style={{ paddingLeft: "0%" }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-outline-dark"
                                        onClick={handlePreview}
                                    >
                                        Preview
                                    </button>
                                </div>
                                <div
                                    className="col"
                                    style={{ position: "relative" }}
                                >
                                    <button
                                        style={{
                                            position: "absolute",
                                            right: "0%",
                                            marginRight: "3%",
                                        }}
                                        type="submit"
                                        className="btn btn-success"
                                    >
                                        Submit
                                    </button>
                                </div>
                                {dispError && <div style = {{marginTop: "3%", marginBottom: "5%"}} ><FormWarning message="Something went wrong on creating your blog post." /></div> }
                            </div>
                        </div>
                    </form>
                </>
            )}
            
        </>
    );
};
export default PostCreate;
