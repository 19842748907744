import axios from 'axios';

export const conductSearch = async (data) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000,
    });
    const searchResults = await axiosInstance.get(`/search/?search=${data}`);
    const resp = searchResults.data;
    if (resp.length > 0) {
        return [resp,true];
    } else {
        return [[],false];
    }
}