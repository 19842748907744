import React, { useState } from "react";
import { useForm } from "react-hook-form";
const CommentForm = ({ onPost, logged }) => {
    const [inputState, changeInputState] = useState(0);
    const updateState = (event) => {
        changeInputState(event.target.value.length);
    };
    const {
        register,
        handleSubmit
    } = useForm();
    return (
        <>
            
            <div
                className="card"
                style={{
                    width: "80%",
                    borderColor: "black",
                    borderWidth: "1px",
                    marginBottom: "5%"
                }}
            >
                <form onSubmit={handleSubmit(onPost)}>
                    <div
                        className="card-body"
                        style={{
                            paddingTop: "1%",
                            borderColor: "black",
                            borderWidth: "1px",
                        }}
                    >
                        <div className="form-group row">
                            <label style={{ fontSize: "150%" }} className="col" >
                                Comment 
                            </label>

                            <div
                                className="row"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <textarea
                                    {...register("content")}
                                    maxLength={250}
                                    onChange={updateState}
                                    className="form-control col"
                                    name = "content"
                                    rows="3"
                                    style={{
                                        marginBottom: "1%",
                                        marginLeft: "4%",
                                        marginRight: "1%",
                                        width: "90%",
                                        resize: "none"
                                    }}
                                ></textarea>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                marginRight: "3%",
                            }}
                        >
                            <div className = "col">
                            {logged && (<p style = {{ color: "red", marginLeft: "4%" }} className = "row">You must be logged in to submit a comment!</p>)}
                            {inputState > 0 && (
                                <p style={{ color: "red", marginLeft: "4%" }} className = "row">
                                    You have {250 - inputState} characters
                                    remaining.
                                </p>
                            )}
                            </div>
                            <button
                                type="submit"
                                className="btn btn-warning"
                                style={{ width: "auto", height: "100%" }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default CommentForm;
