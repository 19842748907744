import React from "react";
import { useForm } from "react-hook-form";
import { FormWarning } from "./FormWarning";
import { cssCenterDiv } from "../../vars";
import { Link } from "react-router-dom";
const LoginForm = ({onSubmit, loginError, errorMessage}) => {
    // eslint-disable-next-line
    const Email_Pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "80%", paddingLeft: "25%" }}
        >
            <div
                className="form-group"
                style={{
                    height: "4vh",
                    width: "100%",
                    ...cssCenterDiv,
                    marginBottom: "4vh",
                }}
            >
                <label htmlFor="email">
                    <h2 style={{ fontSize: "3vh" }}>Email</h2>
                </label>
                <br />
                <input
                    style = {{fontSize: "2vh"}}
                    {...register("email", {
                        required: true,
                        pattern: Email_Pattern,
                        maxLength: 32,
                        minLength: 7,
                    })}
                    name="email"
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="example@email.com"
                />
                {errors.email?.type === "required" && (
                    <FormWarning message="Please enter an email." />
                )}
                {errors.email?.type === "minLength" && (
                    <FormWarning message="The minimum length of email is 7 characters." />
                )}
                {errors.email?.type === "pattern" && (
                    <FormWarning message="Please enter a valid email." />
                )}
                {errors.email?.type === "maxLength" && (
                    <FormWarning message="The maximum length of email is 32 characters." />
                )}
                <br />
            </div>
            <div
                className="form-group"
                style={{
                    height: "4vh",
                    width: "100%",
                    ...cssCenterDiv,
                    marginBottom: "4vh",
                }}
            >
                <label htmlFor="password">
                    <h2 style={{ fontSize: "3vh" }}>Password</h2>
                </label>
                <br />
                <input
                    style = {{fontSize: "2vh"}}
                    {...register("password", {
                        required: true,
                        maxLength: 32,
                        minLength: 8,
                    })}
                    name="password"
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="password"
                />
                {errors.password?.type === "required" && (
                    <FormWarning message="Please enter a password." />
                )}
                {errors.password?.type === "minLength" && (
                    <FormWarning message="The minimum length of the password is 8 characters." />
                )}
                {errors.password?.type === "maxLength" && (
                    <FormWarning message="The maximum length of email is 32 characters." />
                )}

                <br />
                <button type="submit" className="btn btn-warning" style = {{display: "block"}}>Submit</button>
                <p style = {{style: "inline-block",textAlign: "center",fontSize: "2vh",fontWeight: "500",marginTop: "10%"}}>Don't have an account? <Link to = "/register">Register</Link></p>
                {loginError && (
                    <div style = {{margin: "auto", paddingTop: "5%"}}>
                    <FormWarning message = "Incorrect username and/or password." />
                    </div>
                )}
            </div>
        </form>
    );
};
export default LoginForm;
