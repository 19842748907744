import React, { useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { validToken } from "../verification/verifyUser";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { getUserComments } from "../app/features/commentSlice";
import CommentCard from "./components/CommentCard";
const Profile = () => {
    const dispatch = useDispatch();
    const username = useSelector((state) => state.user.username);
    const email = useSelector((state) => state.user.email);
    const token = useSelector((state) => state.user.token);
    const [shouldNav, changeNav] = useState(false);
    const [commentState, changeComments] = useState([]);
    useEffect(() => {
        if (validToken(token)) {
            dispatch(getUserComments({ token: token })).then((resp) => {
                changeComments(resp.payload);
            });
            changeNav(false);
        } else {
            changeNav(true);
        }
    }, [token]);
    return (
        <>
            {shouldNav && <Navigate to="/" />}
            <div style={{ marginTop: "10%", transform: "translateY(-40%)" }}>
                <h1 style={{ fontSize: "4vh" }}>Username:</h1>
                <h1 style={{ textAlign: "center", fontSize: "4vh" }}>
                    {username}
                </h1>
                <h1 style={{ fontSize: "4vh" }}>Email: </h1>
                <h1 style={{ textAlign: "center", fontSize: "4vh" }}>
                    {email}
                </h1>
            </div>
            {commentState.length > 0 &&
                commentState.map((comment) => {
                    return (
                        <CommentCard
                            key={comment._id}
                            content={comment.content}
                            username={comment.userName}
                            createdAt={comment.createdAt}
                            decorate={false}
                            forProfile = {true}
                            toLink = {`/posts/${comment.post}`}
                        />
                    );
                })}
        </>
    );
};
export default Profile;
