import React, { useEffect, useState } from "react";
import { validToken } from "../verification/verifyUser";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
const About = () => {
    const token = useSelector(state => state.user.token);
    const logState = useSelector(state => state.user.loggedIn);
    const [logout,shouldLogout] = useState(false);
    useEffect(() => {
        if (validToken(token,logState)) {
            shouldLogout(true);     
        }
        // eslint-disable-next-line
    },[])
    return (
        <>
        {logout && <Navigate to = "/logout" />}
        <h1>About</h1>
        <p style = {{marginTop: "5%", fontSize: "3vh"}}>
            This website was created by someone born between the years 1997 and 2012.
            I would like to keep my personal identity a secret for my own sake and for the purposes of this blog.
            While I originally intended this blog to mainly focus on cultural commentary,
            I now believe that it would be best to write about anything that comes to my head.
            If you would like to comment on any of the posts that you find interesting, please 
            register with this website using your email. I hope that you enjoy what is written here, and
            whether you do or don't, please let me know with a comment.
        </p>
        </>
    )
}

export default About;