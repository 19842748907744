import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { getPostById } from "../app/features/postSlice";
import CommentForm from "./components/CommentForm";
import { useSelector } from "react-redux";
import { validToken } from "../verification/verifyUser";
import { postComment, getPostComments } from "../app/features/commentSlice";
import { Navigate } from "react-router-dom";
import CommentCard from "./components/CommentCard";
const Post = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.user.token);
    const loggedIn = useSelector((state) => state.user.loggedIn);

    // State
    const [showErr, changeShowErr] = useState(!loggedIn);
    const [isFetching, changeFetch] = useState(true);
    const [commentState, changeCommentState] = useState([]);
    const [pageState, changePageState] = useState({});
    const [shouldNav, changeNav] = useState(false);
    const onPost = (data) => {
        if (validToken(userToken,loggedIn)) {
            changeShowErr(true);
        } else {
            changeFetch(true);
            var dataToSend = {
                ...data,
                postId: id,
                token: userToken,
            };
            dispatch(postComment(dataToSend)).then((resp) => {
                if (resp.payload) {
                    dispatch(getPostComments({ postId: id })).then((resp) => {
                        changeCommentState(resp.payload);
                    });
                    changeFetch(false);
                } else {
                    changeFetch(false);
                    changeNav(true);
                }
            });
        }
    };
    const getFullMonth = (num) => {
        switch (num) {
            case 1:
                return "January";
            case 2:
                return "February";
            case 3:
                return "March";
            case 4:
                return "April";
            case 5:
                return "May";
            case 6:
                return "June";
            case 7:
                return "July";
            case 8:
                return "August";
            case 9:
                return "September";
            case 10:
                return "October";
            case 11:
                return "November";
            case 12:
                return "December";
            default:
                return "Month";
        }
    };
    useEffect(() => {
        dispatch(getPostById(id))
            .then((resp) => {
                const { title, subtitle, comments, content, date } =
                    resp.payload;
                let year = date.slice(0,4);
                let month = getFullMonth(parseInt(date.slice(5,7),10));
                let day = date.slice(8,10);
                try {
                    var content2 = content.split('\n');
                    var content3 = content2.map(paragraph => {
                        if (paragraph === '' || paragraph === undefined) {
                            return;
                        } else {
                            return paragraph;
                        }
                })
                } catch (error) {
                    console.log(error);
                }
                changePageState({
                    title,
                    subtitle,
                    comments,
                    content: content3,
                    date: month + " " + day + ", " + year,
                });
                dispatch(getPostComments({ postId: id }))
                    .then((resp) => {
                        changeCommentState(resp.payload);
                        changeFetch(false);
                    })
                    .catch((error) => {
                        changeFetch(false);
                    });
            })
            .catch((error) => changeFetch(false));
    }, []);
    return (
        <>
            {shouldNav && <Navigate to="/" />}
            {isFetching && (
                <div className="text-center">
                    <div
                        className="spinner-border text-warning"
                        role="status"
                        style={{
                            paddingTop: "30%",
                            width: "30%",
                        }}
                    ></div>
                </div>
            )}
            {!isFetching && (
                <>
                    <h1 style={{ textOverflow: "ellipsis" }}>
                        {pageState.title}
                    </h1>
                    <h2>{pageState.subtitle}</h2>
                    <h3>{pageState.date}</h3>
                    {pageState.content.map((paragraph) => {
                        if (typeof(paragraph) === 'undefined') {return}
                        else{return <p key = {paragraph}>{paragraph}</p>}
                    })}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CommentForm onPost={onPost} logged={showErr} />
                    </div>
                    {commentState.length > 0 &&
                        commentState.map((comment) => {
                            return (
                                <CommentCard
                                    key={comment._id}
                                    content={comment.content}
                                    username={comment.userName}
                                    createdAt={comment.createdAt}
                                    decorate={true}
                                    forProfile = {false}
                                    toLink = {""}
                                />
                            );
                        })}
                </>
            )}
        </>
    );
};
export default Post;
