import React from "react";

const Layout = (props) => {
    return (
        <>
        <div className = "row">
            <div className = "col-2" style={{backgroundColor: "#878E88"}}>
            </div>
            <div className = "col-8">
                <p style = {{minHeight: "2vh"}}></p>
            </div>
            <div className = "col-2" style={{backgroundColor: "#878E88"}}>
            </div>
        </div>
        <div className = "row" style = {{minHeight: "100vh" }}>
            <div className = "col-2" style={{backgroundColor: "#878E88"}}>
            </div>
            <div className = "col-8" style = {{paddingLeft: "3vw", paddingRight: "3vw"}}>
                {props.children}
            </div>
            <div className = "col-2" style={{backgroundColor: "#878E88"}}>
            </div>
        </div>
        </>
    );
}


export default Layout