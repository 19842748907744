import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "./components/Spinner";
import { conductSearch } from "../search";
import PostCard from "./components/PostCard";
export const Search = () => {
    const { any } = useParams();
    const [showResults, changeResults] = useState(false);
    const [data, changeData] = useState([]);
    const [isFetching, changeFetch] = useState(true);
    useEffect(() => {
        conductSearch(any).then((res) => {
            console.log('result in Search.js = ',res);
            changeData(res[0]);
            changeResults(true);
        changeFetch(false);
        })
        
    }, [any]);
    return (
        <>
            <h1>Search results for {decodeURI(any)}</h1>
            {isFetching && (
                <div style={{ marginTop: "10%" }}>
                    <Spinner />
                </div>
            )}
            {showResults && (
                <>
                    {data.map((result) => (
                        <div
                            className="row"
                            key={result._id}
                            style={{
                                marginBottom: "2%",
                                marginTop: "2%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <PostCard
                                to={`/posts/${result._id}`}
                                title={result.title}
                                subtitle={result.subtitle}
                                text={result.content}
                                key={result._id}
                                id={result._id}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    );
};
