import React from "react";
import NewNavbar from "./Navbar/NewNavbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import PostCreate from "./pages/PostCreate";
import Post from './pages/Post';
import { Page404 } from "./pages/Page404";
import { Search } from "./pages/Search";
function App() {
    return (
        <>
            <NewNavbar />
            <Layout>
                <Routes>
                    <Route path = "/search/:any" element = { <Search />} />
                    <Route path="/about" exact element={<About />} />
                    <Route path="/login" exact element={<Login />} />
                    <Route path = "/logout" exact element={<Logout />} />
                    <Route path = "/register" exact element = {<Register/>} />
                    <Route path = "/profile/:id" element = {<Profile />} />
                    <Route path = "/adminpost" exact element = {<PostCreate />} />
                    <Route path = "/posts/:id" element = {<Post />} />
                    <Route path = "/page404" element = { <Page404 />} />
                    <Route path="/" exact element={<Home />} />
                </Routes>
            </Layout>
        </>
    );
}
export default App;
