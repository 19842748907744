import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import "./Navbar.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const notLoggedIn = [
    {
        to: "/about",
        name: "About",
    },
    {
        to: "/login",
        name: "Login",
    },
];
export const loggedIn = [
    {
        to: "/logout",
        name: "Logout",
    },
    {
        to: "/about",
        name: "About",
    },
    {
        to: "/profile",
        name: "Profile",
    },
];
const NewNavbar = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const email = useSelector((state) => state.user.email);
    const adminUser = (email === process.env.REACT_APP_ADMIN_EMAIL ? true : false);
    const isLoggedIn = useSelector((state) => state.user.loggedIn);
    const id = useSelector((state) => state.user.id);

    const onSubmit = (data) => {
        console.log('data.search = ',data.search);
        navigate(`/search/${encodeURIComponent(data.search)}`,{replace: true});
    }
    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light navcontain">
            <div className="container-fluid">
                <Link
                    className="navbar-brand nav-brand-2"
                    to="/"
                    style={{
                        color: "black",
                        fontWeight: "bolder",
                        fontSize: "150%",
                        paddingLeft: "5%",
                    }}
                >
                    gen-z-kid
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarTogglerDemo03"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{
                        color: "black",
                        borderColor: "black",
                        borderWidth: "2px",
                    }}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <div
                        className="navbar-nav"
                        style={{ marginLeft: "auto", paddingRight: "5%" }}
                    >
                        {!isLoggedIn &&
                            notLoggedIn.map((el) => {
                                return (
                                    <li className="nav-item " key={el.to}>
                                        <Link
                                            className="nav-link nav-link-2"
                                            to={el.to}
                                            style={{
                                                color: "black",
                                                fontSize: "125%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {el.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        {isLoggedIn &&
                            loggedIn.map((el) => {
                                return (
                                    <li className="nav-item" key={el.to}>
                                        <Link
                                            className="nav-link nav-link-2"
                                            to={
                                                el.to === "/profile"
                                                    ? `/profile/${id}`
                                                    : el.to
                                            }
                                            key={el.to}
                                            style={{
                                                color: "black",
                                                fontSize: "125%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {el.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        {adminUser &&
                            <Link
                                className="nav-link nav-link-2"
                                to={"/adminpost"}
                                key={"/adminpost"}
                                style={{
                                    color: "black",
                                    fontSize: "125%",
                                    fontWeight: "bold",
                                }}
                            >
                                Post
                            </Link>
                        }
                    </div>
                    <form className="d-flex" role="search" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            className="form-control me-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            {...register("search")}
                            name = "search"
                        />
                        <button className="btn btn-outline-dark" type="submit">
                            Search
                        </button>
                    </form>
                </div>
            </div>
        </nav>
        </>
    );
};
export default NewNavbar;
