import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    id: "",
    title: "",
    subtitle: "",
    content: ""
};

export const createPost = createAsyncThunk("post/createPost", async (data) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000, // everything longer than 5 secs is considered a failed request,
        headers: { Authorization: `Bearer ${data.token}` },
    });
    const postData = {
        title: data.title,
        subtitle: data.subtitle,
        content: data.content
    }
    try {
        const resp = await axiosInstance.post("/posts/makepost", postData);
        const ret = resp.data;
        if (ret.error) {
            return {
                id: "",
                title: "",
                subtitle: "",
                content: "",
            };
        } else {
            return {
                id: ret._id,
                title: ret.title,
                subtitle: ret.subtitle,
                content: ret.content,
            };
        }
    } catch (err) {
        return {
            id: "",
            title: "",
            subtitle: "",
            content: "",
        };
    }
});
export const getPostById = createAsyncThunk('posts/getById', async (id) => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000, 
    });
    const resp = await axiosInstance.get(`/posts/${id}`);
    const data = resp.data;
    var ret = {};
    try {
         ret = {
            id: data._id,
            title: data.title,
            subtitle: data.subtitle,
            comments: data.comments,
            content: data.content,
            date: data.createdAt
        }
        return ret;
    } catch (error) {
        return {
            "error": data.error
        }
    }
})
const postSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        cleanPost: (state) => {
            state.id = "";
            state.title = "";
            state.subtitle = ""; 
            state.content = "";
        },
    },
    extraReducers: builder => {
        builder.addCase(createPost.pending, state => {
            state.id = "";
            state.title = "";
            state.subtitle = "";
            state.content = "";
        });
        builder.addCase(createPost.fulfilled, (state,action) => {
            state.id = action.payload.id;
            state.title = action.payload.title;
            state.subtitle = action.payload.subtitle;
            state.content = action.payload.content;
        })
    }
});
export default postSlice.reducer;
export const { cleanPost } = postSlice.actions;