import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Link } from "react-router-dom";
const CommentCard = (props) => {
    const loggedUser = useSelector((state) => state.user.username);
    const { content, createdAt, username, forProfile, decorate, toLink } =
        props;
    const getFullMonth = (num) => {
        switch (num) {
            case 1:
                return "January";
            case 2:
                return "February";
            case 3:
                return "March";
            case 4:
                return "April";
            case 5:
                return "May";
            case 6:
                return "June";
            case 7:
                return "July";
            case 8:
                return "August";
            case 9:
                return "September";
            case 10:
                return "October";
            case 11:
                return "November";
            case 12:
                return "December";
            default:
                return "Month";
        }
    };
    const [newColor, changeColor] = useState("black");
    const [newBorder, changeBoder] = useState("1px");
    useEffect(() => {
        if (loggedUser === username && decorate) {
            changeColor("green");
            changeBoder("2px");
        }
    }, []);
    const dateClean = (date) => {
        const newDate = new Date(date);
        let year = newDate.getFullYear().toString();
        let day = newDate.getMonth().toString();
        let month = newDate.getUTCMonth();
        month = getFullMonth(month + 1);
        return month + " " + day + ", " + year;
    };
    return (
        <>
            <div
                className="row"
                style={{
                    marginTop: "2%",
                    marginBottom: "2%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "90%",
                        borderColor: `${newColor}`,
                        borderWidth: `${newBorder}`,
                    }}
                >
                    <div className="card-body">
                        {!forProfile && (
                            <>
                                <h5 className="card-title">{username}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    {dateClean(createdAt)}
                                </h6>
                                <p className="card-text">{content}</p>
                            </>
                        )}

                        {forProfile && (
                            <>
                            <div className = "row">
                                <h5
                                    className="card-title col"
                                    style={{ display: "inline-block" }}
                                >
                                    {username}
                                </h5>{" "}
                                <Link to={toLink} className = "col" style = {{textAlign: "end"}}>Go to Blog Post</Link>
                                </div>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    {dateClean(createdAt)}
                                </h6>
                                <p className="card-text">{content}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default CommentCard;
