import React from "react";
import './PostCard.css';
import { Link } from "react-router-dom";
const PostCard = (props) => {
    return (
    <div className="card postCardClass" style={{width: "80%"}}>
    <Link to={props.to} key={props.id} style = {{textDecoration: "none", alignContent: "center", color: "black"}}>
        <div className="card-body postCardClass">
            <h5 className="card-title postCardClassText">{props.title}</h5>
            {(props.subtitle) ?
            <h6 className="card-subtitle mb-2 text-muted postCardClassText">{props.subtitle}</h6> : null
            }
            <p className="card-text postCardClassText">
                {props.text.slice(0,101) + "..."}
            </p>
        </div>
        </Link>
    </div>
    )
}
export default PostCard;
