import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    posts: {
        "id": {
            comments: [],
            title: "",
            subtitle: "",
            content: ""
        },
        "error": "" 
    }
}
// Store in post
export const getPosts = createAsyncThunk("/posts/getposts", async () => {
    const axiosInstance = axios.create({
        baseURL: "/api/v1",
        timeout: 5000
    });
    const resp = await axiosInstance.get('/posts/all');
    if (resp.data.error) {
        return {}
    } else {
        return resp.data;
    }
})
const contentSlice = createSlice({
    name: "content",
    initialState,
    reducers: {
        addPosts : (state,action) => {
            state.posts = {...state.posts, ...action.payload[action.payload.refBy]};
        },
        clearContent : (state) => {
            state.posts = {
                "id": {
                    comments: [],
                    title: "",
                    subtitle: "",
                    content: ""
                }
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getPosts.pending, state => {
            // eslint-disable-next-line
            state.posts = state.posts;
        })
        builder.addCase(getPosts.fulfilled, (state,action) => {
            state.posts = {...state.posts,...action.payload};
        })
    }
})
export default contentSlice.reducer;
export const { addPost, clearContent } = contentSlice.actions;