import React from 'react';

const Logo = () => {
    return (
        <div className = "flex-container" 
        style = {{
            backgroundColor: "#ffdf20",
            color: "#000000",
            fontSize: "3vh",
            fontWeight: "500",
            display: "inline-block",
            position: "relative",
            /* Move the element to the right by 50% of the container's width */
            left: "50%", 
            /* Calculates 50% of the element's width, and moves it by that */ 
            /* amount across the X-axis to the left */
            transform: "translateX(-50%)",
            paddingLeft: "1vw",
            paddingRight: "1vw",
            borderRadius: "10%",
            borderStyle: "solid",
            borderWidth: "0.01vw",
            borderColor: "#878E88"
        }}>
            gen-z-kid
        </div>
    )
}
export default Logo;