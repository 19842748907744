import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import userReducer from './features/userSlice';
import postReducer from './features/postSlice';
import contentReducer from './features/contentSlice';
import commentReducer from './features/commentSlice'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistUserConfig = {
    key: 'user',
    version: 1,
    storage,
}
const persistedUserReducer = persistReducer(persistUserConfig,userReducer);



const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        post: postReducer,
        content: contentReducer,
        comment: commentReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]}})
})
export default store;