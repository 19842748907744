import jwt_decode from "jwt-decode";
export const validToken = (token,loggedIn) => {
    // true => dispatch logout
    try {
        var decoded = jwt_decode(token);
    } catch (error) {
        if (loggedIn) {
            return true;
        } else {
            return false;
        }
    }
    const exp = Number(decoded.exp)*1000;
    const timeNow = Date.now();
    if (exp-timeNow > 0 && loggedIn) {
        return false;
    } else {
        return true;
    }
}